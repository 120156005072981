var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
Backbone.View.fullExtend = require('../utils/Backbone.fullExtend');
var Velocity = require('velocity-animate');

var ImageView = require('./gallery_BaseImage');

var breakpoints = require('../utils/breakpoints');

var Gallery = Backbone.View.extend({
    events: {
        'click .image': 'clickHandler',
        'click .navBttn': 'bttnHandler',
        'click .breadcrumbs .crumb': 'breadcrumbHandler'
    },

    className: 'gallery',

    template: _.template('<% if(navigation){ %><div class="navBttn prev"><div></div></div><div class="navBttn next"><div></div></div><% } %>'),
    breadcrumbTemplate: _.template('<div class="breadcrumbs"><% for(var i = 0; i < size; i++){ %>\
        <div class="crumb"></div>\
    <% } %></div>'),

    defaults: {
        infinite: true,
        transition: 'swipe',
        breadcrumbs: false,
        navigation: true,
        start: 0,
        auto: false
    },
    
    initialize: function(opts){
        _.bindAll(this, 'touchstartHandler', 'touchmoveHandler', 'touchendHandler', 'keyHandler');
        
        this.parent = opts.parent;
        this.collection = new Backbone.Collection(opts.data);

        var settings = _.extend({}, this.defaults, opts);
        settings.cid = this.collection.at(settings.start).get('id');
        delete settings.parent;
        delete settings.data;

        this.model = new Backbone.Model(settings);
        this.images = [];

        if(this.model.get('transition') == 'swipe'){
            if(AE.view.isTouch()){
                this.$el.on('touchstart', this.touchstartHandler);
                this.$el.on('touchmove', this.touchmoveHandler);
                this.$el.on('touchend', this.touchendHandler);
                this.$el.on('touchcancel', this.touchendHandler);
            } else {
                $(window).on('keydown', this.keyHandler);
            }
        }

        this.model.on({
            'change:cid': this.breadcrumbUpdate
        }, this);

        this.listenTo(this.parent.pubSub, {
            'page:removed': this.remove
        }, this);

        this.initThis();
    },

    initThis: function(){},

    breadcrumbUpdate: function(model, id){
        if(!this.model.get('breadcrumbs')){ return; }
        var m = this.collection.get(id);
        var index = this.collection.indexOf(m);

        this.$('.breadcrumbs .crumb.active').removeClass('active');
        this.$('.breadcrumbs .crumb:eq('+index+')').addClass('active');
    },

    touchstartHandler: function(e){
        //e.preventDefault();

        var x = e.originalEvent.touches[0].clientX;
        this.model.set({
            'touchXStart': x,
            'touchX': x,
            'touchMoved': false
        });
    },

    touchmoveHandler: function(e){
        e.preventDefault();
        
        var x = this.model.get('touchXStart');
        var nX = e.originalEvent.touches[0].clientX;
        this.model.set('touchX', nX);
        
        var dist = nX - x;
        Velocity.hook(this.$('.image:not(.imgOut)'), 'translateX', dist+'px');

        if(dist >= 0){
            Velocity.hook(this.$('.image.imgOut.prevImg'), 'translateX', - AE.view.w + dist+'px');
        }
        if(dist <= 0){
            Velocity.hook(this.$('.image.imgOut.nextImg'), 'translateX', AE.view.w + dist+'px');
        }

        if(Math.abs(dist) > 20){
            this.model.set('touchMoved', true);
        }
    },

    touchendHandler: function(e){
        //e.preventDefault();
        var that = this;
        var x = this.model.get('touchXStart');
        var nX = this.model.get('touchX');
        var mult = nX - x;
        var dir;
        if(Math.abs(mult)>75){
            if(mult < 0){
                dir = 'next';
            } else {
                dir = 'prev';
            }
            this.navigateDirection(dir);
        } else {
            if(Math.abs(mult) > 5){
                this.animateImages();
            }
        }
        this.$('.image').attr('style', '');
    },

    keyHandler: function(e){
        if(this.isAnimating){ return; }
        if(e.which == 37){
            this.navigateDirection('prev');
        } else if(e.which == 39){
            this.navigateDirection('next');
        }
    },

    clickHandler: function(e){
        if(this.isAnimating){ return; }
        e.preventDefault();
        e.stopPropagation();
        var x = e.pageX;
        var dir = 'next';
        this.navigateDirection(dir);
    },

    bttnHandler: function(e){
        if(this.isAnimating){ return; }
        e.preventDefault();
        e.stopPropagation();

        var dir;
        if($(e.currentTarget).hasClass('prev')){
            dir = 'prev';
        } else {
            dir = 'next';
        }
        this.navigateDirection(dir);
    },

    breadcrumbHandler: function(e){
        e.preventDefault();
        e.stopPropagation();

        var $e = $(e.currentTarget);
        var index = $e.closest('.breadcrumbs').find('.crumb').index($e);

        this.navigateToIndex(index);
    },

    navigateToIndex: function(i){
        if(this.isAnimating){ return; }

        var model = this.collection.at(i);
        if(!model){ return; }

        var id = model.get('id');
        this.navigateToID(id);
    },

    navigateDirection: function(dir){
        if(this.isAnimating){ return; }

        var id = this.getId(dir);
        this.navigateToID(id);
    },

    navigateToID: function(id){
        if(!_.isUndefined(id)){
            this.resolveImages(id);
            this.animateImages();
        }
    },

    render: function(){
        this.$el.html(this.template(this.model.toJSON()));

        if(this.model.get('breadcrumbs') && this.collection.length > 1){
            this.$el.append(this.breadcrumbTemplate({size: this.collection.length}));
            this.breadcrumbUpdate(this.model, this.model.get('cid'));
        }

        if(this.model.get('infinite')){
            this.$el.addClass('infinite');
        }
        this.$el.addClass('transition-'+this.model.get('transition'));

        this.renderThis();

        this.resolveImages(this.model.get('cid'));
        this.animateImages(true);

        if(this.model.get('auto')){
            this.doLoop();
        }
        return this;
    },

    renderThis: function(){},

    resolveImages: function(id){
        var that = this;
        this.model.set('cid', parseInt(id));
        id = parseInt(id);
        var ids = [id, this.getId('prev'), this.getId('next')];
        _.each(ids, function(_id){
            if(!_.isUndefined(_id)){
                var _img = that.collection.findWhere({id: _id});
                var _view = that.appendOrReturn(_img.id);
            }
        });

        var toPop = [];
        _.each(this.images, function(_image, _i){
            if(!_.contains(ids, parseInt(_image.id))){
                _image.view.remove();
                toPop.push(_i);
            }
        });
        _.each(toPop.reverse(), function(_c){
            that.images.splice(_c,1);
        });
    },

    doLoop: function(){
        if(this.collection.length <= 1){ return; }
        if(this.TO){ clearTimeout(this.TO); }
        var that = this;
        this.TO = setTimeout(function(){
            that.navigateDirection('dir');
        }, 4000);
    },

    animateImages: function(jump){
        if(this.TO){ clearTimeout(this.TO); }
        var that = this;

        if(!jump){
            this.$el.addClass('animating');
            this.isAnimating = true;
            setTimeout(function(){
                that.$el.removeClass('animating');
                that.isAnimating = false;
                that.resolveImages(that.model.get('cid'));
                that.animateImages(true);
                if(that.model.get('auto')){
                    that.doLoop();
                }
            }, 1000);
        }

        var id = this.model.get('cid');
        var currentView = this.appendOrReturn(id);
        currentView.$el.removeClass('imgOut prevImg nextImg');

        if(this.collection.length > 1){
            var prevId, prevView;
            prevId = this.getId('prev');
            if(!_.isUndefined(prevId)){
                prevView = this.appendOrReturn(prevId);
                prevView.$el.removeClass('nextImg').addClass('imgOut prevImg');
                this.$el.removeClass('first');
            } else {
                this.$el.addClass('first');
            }
            
            var nextId, nextView;
            nextId = this.getId('next');
            if(!_.isUndefined(nextId)){
                nextView = this.appendOrReturn(nextId);
                nextView.$el.removeClass('prevImg').addClass('imgOut nextImg');
                this.$el.removeClass('last');
            } else {
                this.$el.addClass('last');
            }
        }

        this.model.set('postId', this.collection.get(id).get('postId'));
    },

    getId: function(direction){
        var id, index, adjacentModel;

        var currentImg = this.collection.findWhere({id: this.model.get('cid')});
        var imgIndex = this.collection.indexOf(currentImg);

        adjacentModel = this.returnModel(direction, imgIndex);
        
        if(adjacentModel){
            id = adjacentModel.get('id');
        }

        return id;
    },

    returnModel: function(direction, index){
        var model;
        var i = index;
        if(direction == 'prev'){
            i--;
        } else {
            i++;
        }
        if(i < 0 || i >= this.collection.length){
            if(!this.model.get('infinite')){
                return false;   
            } else if(i < 0){
                model = this.collection.at(this.collection.length - 1);
            } else {
                model = this.collection.at(0);
            }
        } else {
            model = this.collection.at(i);
        }

        return model;
    },

    appendOrReturn: function(id){
        var view;
        var existing = _.findWhere(this.images, {id: parseInt(id)});
        var model = this.collection.findWhere({id: parseInt(id)});

        if(existing){
            view = existing.view;
        } else {
            view = this.returnImageView({model: model, parent: this});
            this.imageContainer().append(view.render().$el);
            view.resize();
            this.images.push({id: id, view: view});
        }

        return view;
    },

    imageContainer: function(){
        return this.$el;
    },

    returnImageView: function(opts){
        return new ImageView(opts);
    },

    remove: function(){
        this.removeThis();

        if(this.TO){ clearTimeout(this.TO); }

        if(AE.view.isTouch()){
            this.$el.off('touchstart', this.touchstartHandler);
            this.$el.off('touchmove', this.touchmoveHandler);
            this.$el.off('touchend', this.touchendHandler);
            this.$el.off('touchcancel', this.touchendHandler);
        } else {
            $(window).off('keydown', this.keyHandler);
        }

        _.each(this.images, function(_image){
            if(_image.view){
                _image.view.remove();
            }
        })

        this.undelegateEvents();
        this.$el.remove();
    },

    removeThis: function(){}
});

module.exports = Gallery;