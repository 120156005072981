var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
Backbone.View.fullExtend = require('../utils/Backbone.fullExtend');

var imgSize = require('../utils/imgSize');
var preloadImages = require('../utils/preloadImages');

var ImageView = Backbone.View.extend({
    className: 'image isLoading imgOut',
    
    template: _.template('<img src="<%= src %>" width="<%= width %>" height="<%= height %>"/>'),
    
    initialize: function(opts){
        var that = this;
        this.parent = opts.parent;
        this.model = opts.model;

        var src = this.model.get('src');
        preloadImages([src]).done(function(){
            that.$el.removeClass('isLoading');
        });
        AE.pubSub.on('app:resize', this.resize, this);
    },

    render: function(){
        var template = this.template;
        this.$el.html(template(this.model.toJSON()));
        return this;
    },

    resize: function(){
        var $el = this.$('img');
        
        var opts = _.extend({}, this.returnImageOptions($el), this.parent.parent.returnImageOpts($el), {
            $el: $el
        });

        imgSize(opts);
    },

    returnImageOptions: function($img){
        var opts = {};
        var w = $img.attr('width');
        var h = $img.attr('height');

        opts.imgW = w;
        opts.imgH = h;
        opts.width = AE.view.w;

        return opts;
    },

    remove: function(){
        this.undelegateEvents();
        AE.pubSub.off('app:resize', this.resize, this);
        this.$el.remove();
    }

});

module.exports = ImageView