var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var imgSize = require('../utils/imgSize');
var breakpoints = require('../utils/breakpoints');

var _basePage = require('./_basePage');

var AboutUs = _basePage.fullExtend({
    events: {},

	initThis: function(){
		AE.pubSub.on('app:resize', this.resizeThis, this);
		this.pubSub.on('page:removed', this.removeThis, this);

		this.resizeThis();
	},

	resizeThis: function(){
        var $leadRow = this.$('.leadRow');
        $leadRow.each(function(){
            $('.col', this).css({'height': ''});

            var dimensions = imgSize({
                $el: $('.col.image img', this),
                width: AE.view.cW * 0.5,
                midX: true,
                midY: true
            });

            $('.col', this).css({'height': dimensions.height});
        });
	},

	removeThis: function(){
		AE.pubSub.off('app:resize', this.resizeThis);
	},
});

module.exports = AboutUs;