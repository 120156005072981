var Backbone = require('backbone');

var AppRouter = Backbone.Router.extend({
    routes: {
        ':p/': 'getPage',
        '*actions': 'defaultAction'
    },

    initialize: function(){
        this.listenTo(this, 'route', this._trackPageView);
    },
    
    _trackPageView: function(){
        var path = Backbone.history.getFragment();
        //ga('send', 'pageview', {page: "/" + path});
        console.log(path);
    },

    getPage: function(page){
        AE.view.load(page);
    },

    defaultAction: function(){
        AE.view.load();
    }
});

module.exports = AppRouter;