var $ = require('jquery');

var imgSize = function(options){
    var opts = {
        $el: false,
        imgW: false,
        imgH: false,
        width: AE.view.w,
        height: false,
        ratio: false,
        minHeight: false,
        maxHeight: false,
        midX: false,
        midY: false
    };
    
    $.extend(opts, options);
    var nW, nH, imgW, imgH, ratio;

    if(!opts.ratio){
        if(opts.imgW && opts.imgH){
            imgW = opts.imgW;
            imgH = opts.imgH;
        } else {
            imgW = opts.$el.attr('w');
            imgH = opts.$el.attr('h');
            if(!imgW){
                imgW = opts.$el.attr('width');
                imgH = opts.$el.attr('height');
            }
        }
        ratio = imgH/imgW;
    } else {
        ratio = opts.ratio;
    }
    
    if(opts.width&&opts.height){
        nW = opts.width;
        nH = opts.height;
    } else {
        nW = opts.width;
        nH = ratio*nW;
        if(opts.minHeight && nH<opts.minHeight){
            nH = opts.minHeight;
            nW = imgW/imgH*nH;
        } else if(opts.maxHeight && nH>opts.maxHeight){
            nH = opts.maxHeight;
            nW = imgW/imgH*nH;
        }
    }

    nH = Math.floor(nH);
    nW = Math.floor(nW);
    if(opts.$el){
        opts.$el.css({'width': nW, 'height': nH});
        if(opts.midX){ opts.$el.css({'margin-left': -nW/2}); }
        if(opts.midY){ opts.$el.css({'margin-top': -nH/2}); }
    }
    return {
        width: nW,
        height: nH
    };
};

module.exports = imgSize;