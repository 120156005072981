var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var pages = require('./pages');
var HeaderView = require('./components/header');

var breakpoints = require('./utils/breakpoints');

var AppView = Backbone.View.extend({

    el: 'body',

    events: {
        //'click a': 'linkHandler'
    },

    initialize: function(){
    	_.bindAll(this, 'resize', 'scroll');
        $(window).on('resize', this.resize);
        $(window).on('scroll', this.scroll);

        AE.pubSub.on({
            'app:resize:start': this.resizeStart,
            'app:resize:end': this.resizeEnd
        }, this);

    	this.model = new Backbone.Model({
    		'headerHidden': false,
            'hasOverlay': false,
            'isTouch': false
    	});

        if('ontouchstart' in document.documentElement){
            this.model.set('isTouch', true);
            this.$el.addClass('isTouch');
        }

        this.model.on({
            'change:hasOverlay': this.hasOverlayToggle,
            'change:isResizing': this.isResizingToggle
        }, this);
    },

    isTouch: function(){
        return this.model.get('isTouch');
    },

    isMobileLayout: function(){
        return this.model.get('mobileLayout');
    },

    isTabletLayout: function(){
        return this.model.get('tabletLayout');
    },

    hasOverlayToggle: function(model, overlay){
        this.$el.toggleClass('hasOverlay', overlay);
    },

    render: function(){
    	this.headerView = new HeaderView();

    	this.resize();
    	this.scroll();
    },

    load: function(page){
        var that = this;

        if(this.headerView){
            this.headerView.model.set('drawerOpen', false);
        }

        if(!this.view){
            var $el = this.$('.page');
            if($el){
                this.view = this.delegatePage($el);
                this.view.animateIn(true);
            }
        } else {
            var promise, $content;

            var oldView = this.view;
            promise = oldView.animateOut();

            var ajax = $.ajax({
                url: '/'+Backbone.history.fragment,
                error: function (xhr, ajaxOptions, thrownError){
                    var errorHref = window.location.protocol+'//'+window.location.hostname;
                    if(window.location.port){
                        errorHref += ':'+window.location.port;
                    }
                    if(xhr.status == 404) {
                        window.location.replace(errorHref+'/'+Backbone.history.fragment);
                    } else {
                        window.location.href = errorHref+'/'+Backbone.history.fragment;
                    }
                },
                success: function(data, textStatus, xhr){
                    if(_.isObject(xhr.responseJSON) && xhr.responseJSON.external_redirect){
                        window.location.replace(xhr.responseJSON.url);
                        return;
                    }
                    $content = $(data).find('.page');
                }
            });
            $.when(promise, ajax).done(function(){
                oldView.remove();

                $('body, html').scrollTop(0);
                that.$('#container').append($content);
                
                var $el = that.$('.page');
                that.view = that.delegatePage($el);
                setTimeout(function(){
                    that.view.animateIn();
                }, 50);
            });
        }
    },

    delegatePage: function($el){
        var controller = $el.attr('view-controller');
        if(_.isUndefined(pages[controller])){
            controller = 'default';
        }
        return new pages[controller]({el: $el});
    },

    linkHandler: function(e){
        var $el = $(e.currentTarget);
        var href = $el.attr('href');

        var targetHref, internalUrl;

        _.each(AE.homeUrls, function(_url){
            if(internalUrl){ return; }
            var strLength = _url.length;
            if(href.substr(0,strLength)==_url && (href.indexOf('wp-content')<0 && href.indexOf('uploads')<0)){
                targetHref = href.substr(strLength);
                internalUrl = true;
            }
        });
        if(internalUrl){
            if(e){
                e.preventDefault();
                e.stopPropagation();
            }
            this.navigateToPage(targetHref);
        }
    },

    navigateToPage: function(href){
        AE.router.navigate(href, {trigger: true});
    },

    resize: _.throttle(function(){
        if(this.resizeTO){ clearTimeout(this.resizeTO); }
        if(!this.model.get('isResizing')){
            AE.pubSub.trigger('app:resize:start');
        }

        this.w = $(window).width();
        this.h = $(window).height();
        this.cW = this.$('.cont:eq(0) > *:eq(0)').width();

        this.model.set('tabletLayout', AE.view.w <= breakpoints.tabletPortrait && AE.view.w > breakpoints.mobilePortrait);
        this.model.set('mobileLayout', AE.view.w <= breakpoints.mobilePortrait);

        this.resizeTO = setTimeout(function(){
            AE.pubSub.trigger('app:resize:end');
        }, 250);
        AE.pubSub.trigger('app:resize');
    }, 16),

    resizeStart: function(){
        this.model.set('isResizing', true);
    },

    resizeEnd: function(){
        this.model.set('isResizing', false);
    },

    isResizingToggle: function(model, resizing){
        this.$el.toggleClass('isResizing', resizing);
    },

    scroll: function(){
    	var scrollPos = $(window).scrollTop();
    	
    	this.scrollPosition = scrollPos;
        AE.pubSub.trigger('app:scroll');
    },

    headerHeight: function(){
        var h = 0;
        if(this.w <= breakpoints.mobilePortrait){
            h = 65;
        } else if(this.w <= breakpoints.tabletPortrait){
            h = 90;
        }
        return h;
    }

});

module.exports = AppView;