var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');

var AppView = require('./AppView');
var Router = require('./router');

var AE = {};

AE.pubSub = _.extend({}, Backbone.Events);

$(document).ready(function(){
	AE.view = new AppView();
	AE.view.render();
	AE.router = new Router();
	Backbone.history.start({pushState: true, hashChange: false, root: '/'});
});

global.AE = AE;