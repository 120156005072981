var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var imgSize = require('../utils/imgSize');
var breakpoints = require('../utils/breakpoints');

var _basePage = require('./_basePage');

var wc_SingleProduct = _basePage.fullExtend({
    events: {
        'click .reviewShare.reviewToggle': 'toggleReviews'
    },

	initThis: function(){
		AE.pubSub.on('app:resize', this.resizeThis, this);
		this.pubSub.on('page:removed', this.removeThis, this);

		this.resizeThis();
	},

	resizeThis: function(){
        var w = this.getImagesWidth();
        var h = this.getImagesHeight(w);

        this.$('.imagesCont, .infoCont').css({'height': h});
	},

    getImagesWidth: function(){
        var $images = this.$('.imagesCont');
        return $images.width();
    },

    getImagesHeight: function(w){
        return Math.max(w, this.$('.infoCont .inner').outerHeight());
    },

    toggleReviews: function(e){
        e.preventDefault();
        e.stopPropagation();
        $(e.currentTarget).closest('.floatBlocks.information').toggleClass('reviewsShowing');
    },

	removeThis: function(){
		AE.pubSub.off('app:resize', this.resizeThis);
	},

    returnImageOpts: function($el){
        var w = this.getImagesWidth();
        var h = this.getImagesHeight(w);
        
        return {
            width: w,
            maxHeight: h,
            midY: true
        };
    }
});

module.exports = wc_SingleProduct;