var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
var Velocity = require('velocity-animate');

var breakpoints = require('../utils/breakpoints');

var HeaderView = Backbone.View.extend({
    el: 'header#header',

    events: {
        'mouseenter .hasDropdown': 'dropdownOpen',
        'mouseleave .hasDropdown': 'dropdownClose',
        'click .mobileNav': 'toggleMobileMenuHandler',
        'click .hasDropdown > a': 'mobileSubMenuHandler'
    },

    initialize: function(){
        this.model = new Backbone.Model({
            height: 174,
            headerMinimized: false,
            mobileMenu: false
        });

        AE.pubSub.on('app:resize', this.resize, this);
        AE.pubSub.on('app:scroll', this.scroll, this);
        this.resize();
        this.scroll();

        this.model.on({
            'change:headerMinimized': this.minimizedToggle,
            'change:mobileMenu': this.mobileToggle,
            'change:mobileMenuOpen': this.mobileMenuToggle
        }, this);

        this.render();
    },

    toggleMobileMenuHandler: function(e){
        e.preventDefault();
        e.stopPropagation();
        this.model.set('mobileMenuOpen', !this.model.get('mobileMenuOpen'));
    },

    mobileToggle: function(model, mobile){
        if(mobile){
            this.model.set('headerMinimized', false);
            this.$('.pseudoDrop, .dropdownOpen .dropdown').attr('style', '');
        } else {
            this.model.set('mobileMenuOpen', false);
        }
    },

    mobileMenuToggle: function(model, menu){
        this.$el.toggleClass('mobileMenuOpen', menu);
    },

    resize: function(){
        this.model.set('mobileMenu', AE.view.w <= breakpoints.tablet);
    },

    scroll: function(){
        this.model.set('headerMinimized', AE.view.scrollPosition > 60 && !this.model.get('mobileMenu'));
    },

    minimizedToggle: function(model, min){
        this.$el.toggleClass('minimized', min);
    },

    dropdownOpen: function(e){
        if(this.model.get('mobileMenu')){ return; }
        var $e = $(e.currentTarget);

        $e.addClass('dropdownOpen');

        var $pseudoDrop = this.$('.pseudoDrop');
        var $dropdown = $e.find('.dropdown');

        $dropdown.addClass('tempShow');
        var dropHeight = $dropdown.height();
        $dropdown.removeClass('tempShow');

        Velocity($dropdown, 'stop');
        Velocity($pseudoDrop, 'stop');

        Velocity($dropdown, {
            height: [dropHeight, 0],
            opacity: [1, 0]
        }, {
            display: 'block',
            duration: 700,
            queue: false
        });

        Velocity($pseudoDrop, {
            height: dropHeight
        }, {
            display: 'block',
            duration: 700,
            queue: false
        });
    },

    dropdownClose: function(e){
        if(this.model.get('mobileMenu')){ return; }
        var $e = $(e.currentTarget);

        $e.removeClass('dropdownOpen');

        var $pseudoDrop = this.$('.pseudoDrop');
        var $dropdown = $e.find('.dropdown');

        Velocity($dropdown, 'stop');
        Velocity($pseudoDrop, 'stop');

        Velocity($dropdown, {
            height: 0,
            opacity: 0
        }, {
            display: 'none',
            duration: 400,
            queue: false
        });

        Velocity($pseudoDrop, {
            height: 0
        }, {
            display: 'none',
            duration: 400,
            queue: false
        });
    },

    delegateHeader: function(path){
        
    },

    mobileSubMenuHandler: function(e){
        var $e = $(e.currentTarget);
        var $li = $e.closest('.hasDropdown');

        var open = true;
        if(!$li.hasClass('dropdownOpen')){
            e.preventDefault();
            e.stopPropagation();
            this.$('.dropdownOpen').removeClass('dropdownOpen');
            $li.addClass('dropdownOpen');
        }
    }
});

module.exports = HeaderView;