var breakpoints = {
	desktop: 1280,
	tabletIPad: 1024,
	tablet: 980,
	tabletIPadPortrait: 768,
	phoneLarge: 640,
	phone: 400,
	phoneSmall: 320
}

module.exports = breakpoints;