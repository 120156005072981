var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var imgSize = require('../utils/imgSize');
var breakpoints = require('../utils/breakpoints');

var _basePage = require('./_basePage');

var Home = _basePage.fullExtend({
    events: {},

	initThis: function(){
		AE.pubSub.on('app:resize', this.resizeThis, this);
		this.pubSub.on('page:removed', this.removeThis, this);

        if(this.galleries.length > 0){
            this.slider = this.galleries[0];
            this.listenTo(this.slider.model, 'change:cid', this.updateProduct, this);
            this.updateProduct(this.slider.model, this.slider.model.get('cid'));
        }

		this.resizeThis();

        this.testimonialLoop();
	},

    updateProduct: function(model, cid){
        var m = this.slider.collection.get(cid);

        var $sliderOverlay = this.$('section[type="landingArea"] .overlay');
        $sliderOverlay.find('.productLink').remove();

        if(m.get('productLink')){
            $sliderOverlay.append(_.template('<a href="<%= link %>" class="productLink"><span><%= name %></span></a>')({link: m.get('productLink'), name: m.get('productName')}));
        }
    },

	resizeThis: function(){
        this.$('section[type="landingArea"]').css({'height': this.getLandingHeight() });

        var $caromiche = this.$('section[type="caromiche"]');
        $caromiche.each(function(){
            $('.col', this).css({'height': ''});

            var textHeight = $('.col.text .textContent', this).height();
            textHeight = Math.max(textHeight, 200);

            var dimensions = imgSize({
                $el: $('.col.image img', this),
                width: AE.view.cW * 0.5,
                minHeight: textHeight,
                midX: true,
                midY: true
            });

            textHeight = Math.max(textHeight, dimensions.height * 0.8);

            $('.col', this).css({'height': textHeight});
        });
	},

	removeThis: function(){
		AE.pubSub.off('app:resize', this.resizeThis);
	},

    getLandingHeight: function(){
        var $landing = this.$('section[type="landingArea"]');
        var landingHeight = 0;

        landingHeight = AE.view.cW * 0.52;
        var overlayHeight = $landing.find('.overlay .inner').outerHeight();
        if(overlayHeight > landingHeight){
            landingHeight = overlayHeight;
        }
        return landingHeight;
    },

    returnImageOpts: function($el){
        return {
            width: AE.view.cW,
            minHeight: this.getLandingHeight(),
            midX: true,
            midY: true
        };
    },

    testimonialLoop: function(){
        var that = this;
        var $section = this.$('section[type="testimonial"]');

        var $currentSlide = $section.find('.visible');
        var $newSlide = $currentSlide.next('li');
        if(!$currentSlide.length > 0 || !$newSlide.length > 0){
            $newSlide = $section.find('li:eq(0)');
        }

        var delay = 0;

        if($currentSlide.length > 0){
            $currentSlide.addClass('out');
            delay = 1000;
        }
        setTimeout(function(){
            if($currentSlide.length > 0){
                $currentSlide.removeClass('visible');
            }
            $newSlide.addClass('visible out');
            setTimeout(function(){
                $newSlide.removeClass('out');
            }, 50);
        }, delay);
        
        setTimeout(function(){
            if($section.find('li').length > 1){
                that.testimonialLoop();
            }
        }, 5000 + delay);
    }
});

module.exports = Home;