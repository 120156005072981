var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
Backbone.View.fullExtend = require('../utils/Backbone.fullExtend');
var Velocity = require('velocity-animate');

var imgSize = require('../utils/imgSize');
var breakpoints = require('../utils/breakpoints');

var GalleryView = require('../components/gallery_Base')

var PageView = Backbone.View.extend({
	events: {},

	initialize: function(){
		var that = this;

		this.model = new Backbone.Model({});

		this.pubSub = _.extend({}, Backbone.Events);
		this.pubSub.on('page:loaded', this.pageLoaded, this);

		this.resize();

		this.galleries = [];

		this.$('.galleryBuild').each(function(){
			var array = [];

			$('img', this).each(function(i){
				var $obj = $(this);
	            var opts = {
	                id:         i,
	                src:        $obj.attr('src'),
	                width:      parseInt($obj.attr('width')),
	                height:     parseInt($obj.attr('height'))
	            };
	            if($obj.attr('product-link')){
	            	opts.productLink = $obj.attr('product-link');
	            	opts.productName = $obj.attr('product-name');
	            }
	            array.push(opts);
			});

			var obj = _.extend({}, JSON.parse($(this).attr('data-settings')), {
				data: array,
                parent: that
			});

			if(array.length > 0){
				var gallery = new GalleryView(obj);
				$(this).parent().append(gallery.render().$el);
				that.galleries.push(gallery);
			}

			$(this).remove();
		});


		$("#moreclaims_button").click(function () {
			$("#moreclaims_content").stop().slideToggle();
			return false;
		});
		$("#moreclaims_button").hover(function () {
			$(this).stop().css({
			    "cursor": "pointer"
			});
		});

		AE.pubSub.on('app:resize', this.resize, this);
		this.model.on({}, this);

		this.initThis();
	},

	initThis: function(){
		return false;
	},

	resize: function(){
		this.$('.forceSize').each(function(){
			var $img = $(this);
			$img.css({'width': '', 'height': ''});
			
			var w = $img.width();
			var imgW = $img.attr('width');
			if(imgW < w){
				w = imgW;
			}

			imgSize({
				$el: $img,
				width: w
			});
		});
	},

	animateIn: function(){
		var that = this;
		var promise = $.Deferred();
		
		that.pubSub.trigger('page:loading');
		this.$el.addClass('viewIn');
		setTimeout(function(){
			promise.resolve();
			that.pubSub.trigger('page:loaded');
		}, 800);
		return promise.promise();
	},

	animateOut: function(){
		var promise = $.Deferred();

		this.pubSub.trigger('page:removing');
		this.$el.removeClass('viewIn pageLoaded');
		setTimeout(function(){
			promise.resolve();
		}, 800);
		return promise.promise();
	},

	pageLoaded: function(){
		this.$el.addClass('pageLoaded');
	},

	returnImageOpts: function($el){
		return {};
	},

	remove: function(){
		this.pubSub.trigger('page:removed');
		this.undelegateEvents();
		this.$el.remove();
	}
});

module.exports = PageView;